import mascotte from "../../Assets/mascotte_velomad.png";

export default function SingeFooterShopifyVelomad() {
    return (
        <div className="section-mascotte">
            <div className="found-map-title ">
                <img
                    src={mascotte}
                    alt="Mascotte VeloMad"
                    className="mascotte-velomad"
                />
                <p>Retours Gratuit</p>
                <small>14 jours pour changer d'avis</small>
            </div>

            <div className="found-map-title ">
                <img
                    src={mascotte}
                    alt="Mascotte VeloMad"
                    className="mascotte-velomad"
                />
                <p>LIVRAISON À VOTRE DOMICILE</p>
                <small>Partout en France et en UE</small>
            </div>

            <div className="found-map-title ">
                <img
                    src={mascotte}
                    alt="Mascotte VeloMad"
                    className="mascotte-velomad"
                />
                <p>Garantie 2 ans</p>
                <small>Un soucis ? Notre SAV est là</small>
            </div>
        </div>
    );
}
