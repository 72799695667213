/* eslint-disable array-callback-return */
import { useDispatch, useSelector } from "react-redux";
import {
    getLocations,
    getOrdersByDateTdb,
} from "../../Redux/actions/stats.actions";
import { toast } from "react-toastify";

import { useEffect } from "react";
import "../../Pages/sav.css";
import "../../Pages/stats.css";
import { useState } from "react";
import { MdLocalShipping } from "react-icons/md";
import { IoIosBicycle } from "react-icons/io";
import { BsBoxArrowUpRight, BsFiletypePdf } from "react-icons/bs";
import { FaEuroSign } from "react-icons/fa";

export default function TDBStats() {
    document.title = "VELOMAD - TDB";
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const accessToken = useSelector((store) => store.user.accessToken);

    const dispatch = useDispatch();
    const statsTdb = useSelector((state) => state.Stats.statsTdb);

    const dateStart = useSelector((state) => state.Stats.dateStart);
    const dateEnd = useSelector((state) => state.Stats.dateEnd);

    useEffect(() => {
        dispatch(getLocations(accessToken));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (statsTdb === null && statsTdb === undefined) {
            dispatch(getOrdersByDateTdb(dateStart, dateEnd, accessToken));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (
            dateStart !== null &&
            dateStart !== undefined &&
            dateEnd !== null &&
            dateEnd
        ) {
            dispatch(getOrdersByDateTdb(dateStart, dateEnd, accessToken));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateStart, dateEnd]);

    const [urlCsvOrdersDate, setUrlCsvOrdersDate] = useState(undefined);

    const handleSubmit = (e) => {
        e.preventDefault();

        let table = undefined;
        table = document.getElementById("TdbDetailsTable");

        const thead = Array.from(table.tHead.rows[0].children).map(
            (el) => el.textContent
        );

        const tbody = Array.from(table.tBodies[0].rows).map((row) =>
            Array.from(row.cells).map((cell) => cell.textContent)
        );

        const obj = {
            table: [thead].concat(tbody),
        };

        const formData = new FormData();

        formData.append("type", "export_orders");
        formData.append("table", JSON.stringify(obj));

        if (e.target.innerText.includes("Vélos")) {
            formData.append(
                "Title",
                "Details_Velos_DU_" + dateStart + "_AU_" + dateEnd
            );
        }

        async function PostCreateCSV(formData) {
            if (e.target.innerText.includes("Vélos")) {
                setUrlCsvOrdersDate(undefined);
            }

            try {
                toast(
                    "Envoi de la demande d'export en cours... Merci de patienter",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                    }
                );
                const response = await fetch(
                    `https://api.levelomad.com/api/stats/v1/create_new_file_compta/`,
                    {
                        method: "post",
                        headers: new Headers({
                            Accept: "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 404) {
                    toast.error(
                        "La connexion au serveur est introuvable : " +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 200) {
                    if (e.target.innerText.includes("Vélos")) {
                        setUrlCsvOrdersDate(data);
                    }
                } else if (response.status === 401) {
                    toast.warning("Echec Authentication - session périmée", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error("Une erreur s'est produite " + data.detail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible de créer une nouvelle Demande 😔 Contactez le support",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        PostCreateCSV(formData);
    };

    return (
        <main>
            <h1 id="selectCmde">Tableau de Bord</h1>
            {dateStart !== null && dateEnd !== null && statsTdb !== null ? (
                <>
                    <div className="text-center f-column">
                        <button
                            className="btn"
                            onClick={(e) => handleSubmit(e)}
                        >
                            Demande d'export Vélos
                        </button>
                        {urlCsvOrdersDate !== undefined && (
                            <a
                                href={`https://api.levelomad.com/media/stats_orders/${urlCsvOrdersDate.url_filename_stock}?auth_token=${accessToken}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button className="btn-export-csv ">
                                    Obtenir le CSV d'export <BsFiletypePdf />{" "}
                                    <BsBoxArrowUpRight />
                                </button>
                            </a>
                        )}
                    </div>

                    <p>
                        Nous avons initialement {statsTdb?.count} commandes avec{" "}
                        {statsTdb?.count - statsTdb?.stats?.total_order}{" "}
                        annulées - du{" "}
                        {statsTdb?.intervalle[1]
                            .split("+")[0]
                            .replace("T", " ")}{" "}
                        au{" "}
                        {statsTdb?.intervalle[0]
                            .split("+")[0]
                            .replace("T", " ")}
                    </p>

                    <div className="table-responsive">
                        <table id="TdbDetailsTable">
                            <thead>
                                <tr>
                                    <th className="bg-table sticky-top sticky-left z-index-4">
                                        Date
                                    </th>
                                    <th className="bg-table sticky-top">
                                        Total
                                    </th>

                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <th
                                                className="bg-table sticky-top"
                                                key={index}
                                            >
                                                {key}
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="total-showroom">
                                    <td className="sticky-left">
                                        Total Retail
                                    </td>
                                    <td>
                                        {statsTdb["stats"]["q_paris_velo"] +
                                            statsTdb["stats"]["q_rouen_velo"]}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td key={index}>
                                                {statsTdb["stats"]["date"][key][
                                                    "q_paris_velo"
                                                ] +
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_rouen_velo"]}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-velo-paris">
                                    <td className="sticky-left">
                                        Showroom Paris
                                    </td>
                                    <td>{statsTdb["stats"]["q_paris_velo"]}</td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td key={index}>
                                                {
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_paris_velo"]
                                                }
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-velo-rouen">
                                    <td className="sticky-left">
                                        Showroom Rouen
                                    </td>
                                    <td>{statsTdb["stats"]["q_rouen_velo"]}</td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td key={index}>
                                                {
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_rouen_velo"]
                                                }
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-velo-site">
                                    <td className="sticky-left">Online</td>
                                    <td>{statsTdb["stats"]["q_site_velo"]}</td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td key={index}>
                                                {
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_site_velo"]
                                                }
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-velo-b2b">
                                    <td className="sticky-left">B2B</td>
                                    <td className="">
                                        {statsTdb["stats"]["q_b2b_velo"]}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td key={index}>
                                                {
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_b2b_velo"]
                                                }
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-velo">
                                    <td className="bg-table sticky-left">
                                        Total Vélos
                                    </td>
                                    <td className="bg-table">
                                        {statsTdb["stats"]["q_velo"]}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td
                                                key={index}
                                                className="bg-table"
                                            >
                                                {
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_velo"]
                                                }
                                            </td>
                                        ))}
                                </tr>
                                <tr>
                                    <td className="bg-table sticky-left"></td>{" "}
                                    <td className="bg-table"></td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td className="bg-table"></td>
                                        ))}
                                </tr>{" "}
                                <tr id="total-precommande">
                                    <td className="sticky-left">precommande</td>
                                    <td>
                                        {
                                            statsTdb["stats"][
                                                "q_precommande_velo"
                                            ]
                                        }
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td key={index}>
                                                {
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_precommande_velo"]
                                                }
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-fabrication">
                                    <td className="sticky-left">fabrication</td>
                                    <td>
                                        {
                                            statsTdb["stats"][
                                                "q_fabrication_velo"
                                            ]
                                        }
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td key={index}>
                                                {
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_fabrication_velo"]
                                                }
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-reconditionne">
                                    <td className="sticky-left">
                                        reconditionne
                                    </td>
                                    <td>
                                        {
                                            statsTdb["stats"][
                                                "q_reconditionne_velo"
                                            ]
                                        }
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td key={index}>
                                                {
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_reconditionne_velo"]
                                                }
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-stockb2b">
                                    <td className="sticky-left">stockB2B</td>
                                    <td>
                                        {statsTdb["stats"]["q_stockb2b_velo"]}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td key={index}>
                                                {
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_stockb2b_velo"]
                                                }
                                            </td>
                                        ))}
                                </tr>{" "}
                                <tr id="total-stock">
                                    <td className="sticky-left">
                                        Autres & stock
                                    </td>
                                    <td>{statsTdb["stats"]["q_stock_velo"]}</td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td key={index}>
                                                {
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_stock_velo"]
                                                }
                                            </td>
                                        ))}
                                </tr>{" "}
                                <tr>
                                    <td className="bg-table sticky-left">
                                        CA HT
                                    </td>{" "}
                                    <td className="bg-table"></td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td className="bg-table"></td>
                                        ))}
                                </tr>
                                <tr id="commande-ht-paris">
                                    <td className="sticky-left">Paris</td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_HT_paris"
                                            ].toFixed(2)
                                        )}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_HT_paris"].toFixed(
                                                        2
                                                    )
                                                )}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-ht-rouen">
                                    <td className="sticky-left">Rouen</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["total_HT_rouen"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_HT_rouen"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-ht-site">
                                    <td className="sticky-left">Site</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["total_HT_site"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_HT_site"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-ht-b2b">
                                    <td className="sticky-left">b2b</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["total_HT_b2b"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_HT_b2b"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>{" "}
                                <tr id="commande-ht-total">
                                    <td className="bg-table sticky-left">
                                        CA HT Total
                                    </td>{" "}
                                    <td className="bg-table">
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["total_HT"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td className="bg-table">
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_HT"].toFixed(2)
                                                )}
                                            </td>
                                        ))}
                                </tr>
                                <tr>
                                    <td className="bg-table sticky-left">
                                        CA TTC
                                    </td>{" "}
                                    <td className="bg-table"></td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td className="bg-table"></td>
                                        ))}
                                </tr>
                                <tr id="commande-ttc-paris">
                                    <td className="sticky-left">Paris</td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_paris"
                                            ].toFixed(2)
                                        )}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "total_TTC_paris"
                                                    ].toFixed(2)
                                                )}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-ttc-rouen">
                                    <td className="sticky-left">Rouen</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["total_TTC_rouen"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_TTC_rouen"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-ttc-site">
                                    <td className="sticky-left">Site</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["total_TTC_site"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_TTC_site"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-ttc-b2b">
                                    <td className="sticky-left">b2b</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["total_TTC_b2b"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_TTC_b2b"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>{" "}
                                <tr id="commande-ttc-total">
                                    <td className="bg-table sticky-left">
                                        CA TTC Total
                                    </td>{" "}
                                    <td className="bg-table">
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["total_TTC"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td className="bg-table">
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_TTC"].toFixed(2)
                                                )}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-ttc-precommande">
                                    <td className="sticky-left">precommande</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_precommande_velo"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "total_TTC_precommande_velo"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>{" "}
                                <tr id="commande-ttc-fabrication">
                                    <td className="sticky-left">fabrication</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_fabrication_velo"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "total_TTC_fabrication_velo"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-ttc-reconditionne">
                                    <td className="sticky-left">
                                        reconditionne
                                    </td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_reconditionne_velo"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "total_TTC_reconditionne_velo"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-ttc-stock">
                                    <td className="sticky-left">stock</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_stock_velo"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_TTC_stock_velo"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr>
                                    <td className="bg-table sticky-left">
                                        Reste Dû TTC
                                    </td>{" "}
                                    <td className="bg-table"></td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td className="bg-table"></td>
                                        ))}
                                </tr>
                                <tr id="reste-du-paris">
                                    <td className="sticky-left">Paris</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["reste_du_paris"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["reste_du_paris"]
                                                ).toFixed(2)}{" "}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="reste-du-rouen">
                                    <td className="sticky-left">Rouen</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["reste_du_rouen"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["reste_du_rouen"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="reste-du-site">
                                    <td className="sticky-left">Site</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"][
                                                "reste_du_site"
                                            ].toFixed(2)
                                        )}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["reste_du_site"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="reste-du-b2b">
                                    <td className="sticky-left">B2B</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"][
                                                "reste_du_b2b"
                                            ].toFixed(2)
                                        )}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["reste_du_b2b"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="reste-du-total">
                                    <td className="bg-table sticky-left">
                                        Total
                                    </td>{" "}
                                    <td className="bg-table">
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["reste_du"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td className="bg-table">
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["reste_du"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="reste-du-precommande">
                                    <td className="sticky-left">precommande</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"][
                                                "reste_du_precommande_velo"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "reste_du_precommande_velo"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="reste-du-fabrication">
                                    <td className="sticky-left">fabrication</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"][
                                                "reste_du_fabrication_velo"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "reste_du_fabrication_velo"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="reste-du-recondtionne">
                                    <td className="sticky-left">
                                        reconditionne
                                    </td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"][
                                                "reste_du_reconditionne_velo"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "reste_du_reconditionne_velo"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>{" "}
                                <tr id="reste-du-b2b">
                                    <td className="sticky-left">stock B2b</td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"]["reste_du_b2b"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["reste_du_b2b"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="reste-du-stock">
                                    <td className="sticky-left">
                                        Autre tags (dont stock)
                                    </td>{" "}
                                    <td>
                                        {" "}
                                        {Number(
                                            statsTdb["stats"][
                                                "reste_du_stock_velo"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["reste_du_stock_velo"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr>
                                    <td className="bg-table sticky-left">
                                        Réparation TTC
                                    </td>{" "}
                                    <td className="bg-table"></td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td className="bg-table"></td>
                                        ))}
                                </tr>
                                <tr id="reparation-paris">
                                    <td className="sticky-left">Paris</td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_reparation_paris"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {" "}
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "total_TTC_reparation_paris"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="reparation-rouen">
                                    <td className="sticky-left">Rouen</td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_reparation_rouen"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {" "}
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "total_TTC_reparation_rouen"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="reparation-total">
                                    <td className="bg-table sticky-left">
                                        Total
                                    </td>{" "}
                                    <td className="bg-table">
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_reparation_total"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td className="bg-table">
                                                {" "}
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "total_TTC_reparation_total"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-securite">
                                    <td className=" sticky-left">
                                        Sécurité TTC
                                    </td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_securite"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_TTC_securite"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-securite" className="hidden">
                                    <td className="sticky-left">
                                        <a
                                            href={
                                                "https://admin.shopify.com/store/velomad/collections/447361089817"
                                            }
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            Collection Sécurité
                                        </a>
                                    </td>{" "}
                                    <td>
                                        {
                                            Object.keys(
                                                statsTdb["stats"][
                                                    "order_securite"
                                                ]
                                            ).length
                                        }
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {" "}
                                                {Object.keys(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["order_securite"]
                                                ).map((orderId, index) => (
                                                    <span>
                                                        {" "}
                                                        <a
                                                            href={
                                                                "https://admin.shopify.com/store/velomad/orders/" +
                                                                orderId
                                                            }
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            className="commandes-velos"
                                                        >
                                                            {
                                                                statsTdb[
                                                                    "stats"
                                                                ]["date"][key][
                                                                    "order_securite"
                                                                ][orderId]
                                                            }{" "}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                "fulfilled" && (
                                                                <MdLocalShipping className="text-green" />
                                                            )}{" "}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                "partial" && (
                                                                <MdLocalShipping className="text-paid" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                null && (
                                                                <MdLocalShipping className="text-red" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "paid" && (
                                                                <FaEuroSign className="text-green" />
                                                            )}{" "}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "partially_paid" && (
                                                                <FaEuroSign className="text-paid" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "pending" && (
                                                                <FaEuroSign className="text-red" />
                                                            )}
                                                        </a>
                                                    </span>
                                                ))}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-accessoire">
                                    <td className=" sticky-left">
                                        accessoire TTC
                                    </td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_accessoire"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_TTC_accessoire"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-accessoire" className="hidden">
                                    <td className="sticky-left">
                                        <a
                                            href={
                                                "https://admin.shopify.com/store/velomad/collections/447360991513"
                                            }
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            Collection Accessoires
                                        </a>{" "}
                                        <br />& <br />
                                        <a
                                            href={
                                                "https://admin.shopify.com/store/velomad/collections/463475409177"
                                            }
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            Collection Nos Accessoires
                                        </a>
                                    </td>{" "}
                                    <td>
                                        {
                                            Object.keys(
                                                statsTdb["stats"][
                                                    "order_accessoire"
                                                ]
                                            ).length
                                        }
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {" "}
                                                {Object.keys(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["order_accessoire"]
                                                ).map((orderId, index) => (
                                                    <span>
                                                        {" "}
                                                        <a
                                                            href={
                                                                "https://admin.shopify.com/store/velomad/orders/" +
                                                                orderId
                                                            }
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            className="commandes-velos"
                                                        >
                                                            {
                                                                statsTdb[
                                                                    "stats"
                                                                ]["date"][key][
                                                                    "order_accessoire"
                                                                ][orderId]
                                                            }{" "}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                "fulfilled" && (
                                                                <MdLocalShipping className="text-green" />
                                                            )}{" "}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                "partial" && (
                                                                <MdLocalShipping className="text-paid" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                null && (
                                                                <MdLocalShipping className="text-red" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "paid" && (
                                                                <FaEuroSign className="text-green" />
                                                            )}{" "}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "partially_paid" && (
                                                                <FaEuroSign className="text-paid" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "pending" && (
                                                                <FaEuroSign className="text-red" />
                                                            )}
                                                        </a>
                                                    </span>
                                                ))}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-bagagerie">
                                    <td className=" sticky-left">
                                        bagagerie TTC
                                    </td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_bagagerie"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_TTC_bagagerie"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="commande-bagagerie" className="hidden">
                                    <td className="sticky-left">
                                        <a
                                            href={
                                                "https://admin.shopify.com/store/velomad/collections/447361057049"
                                            }
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            Collection Bagaerie
                                        </a>{" "}
                                    </td>{" "}
                                    <td>
                                        {
                                            Object.keys(
                                                statsTdb["stats"][
                                                    "order_bagagerie"
                                                ]
                                            ).length
                                        }
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {" "}
                                                {Object.keys(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["order_bagagerie"]
                                                ).map((orderId, index) => (
                                                    <span>
                                                        {" "}
                                                        <a
                                                            href={
                                                                "https://admin.shopify.com/store/velomad/orders/" +
                                                                orderId
                                                            }
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            className="commandes-velos"
                                                        >
                                                            {
                                                                statsTdb[
                                                                    "stats"
                                                                ]["date"][key][
                                                                    "order_bagagerie"
                                                                ][orderId]
                                                            }{" "}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                "fulfilled" && (
                                                                <MdLocalShipping className="text-green" />
                                                            )}{" "}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                "partial" && (
                                                                <MdLocalShipping className="text-paid" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                null && (
                                                                <MdLocalShipping className="text-red" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "paid" && (
                                                                <FaEuroSign className="text-green" />
                                                            )}{" "}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "partially_paid" && (
                                                                <FaEuroSign className="text-paid" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "pending" && (
                                                                <FaEuroSign className="text-red" />
                                                            )}
                                                        </a>
                                                    </span>
                                                ))}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-piece_detachees">
                                    <td className=" sticky-left">
                                        piece_detachees TTC Paris
                                    </td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_piece_detachees_paris"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "total_TTC_piece_detachees_paris"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-piece_detachees">
                                    <td className=" sticky-left">
                                        piece_detachees TTC Rouen
                                    </td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_piece_detachees_rouen"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "total_TTC_piece_detachees_rouen"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-piece_detachees">
                                    <td className=" sticky-left">
                                        piece_detachees TTC
                                    </td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_TTC_piece_detachees"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "total_TTC_piece_detachees"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr
                                    id="commande-piece_detachees"
                                    className="hidden"
                                >
                                    <td className="sticky-left">
                                        <a
                                            href={
                                                "https://admin.shopify.com/store/velomad/collections/447361155353"
                                            }
                                            rel="noreferrer"
                                            target="_blank"
                                        >
                                            Collection piece_detachees
                                        </a>{" "}
                                    </td>{" "}
                                    <td>
                                        {
                                            Object.keys(
                                                statsTdb["stats"][
                                                    "order_piece_detachees"
                                                ]
                                            ).length
                                        }
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {" "}
                                                {Object.keys(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["order_piece_detachees"]
                                                ).map((orderId, index) => (
                                                    <span>
                                                        {" "}
                                                        <a
                                                            href={
                                                                "https://admin.shopify.com/store/velomad/orders/" +
                                                                orderId
                                                            }
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            className="commandes-velos"
                                                        >
                                                            {
                                                                statsTdb[
                                                                    "stats"
                                                                ]["date"][key][
                                                                    "order_piece_detachees"
                                                                ][orderId]
                                                            }
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                "fulfilled" && (
                                                                <MdLocalShipping className="text-green" />
                                                            )}{" "}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                "partial" && (
                                                                <MdLocalShipping className="text-paid" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .fulfillment_status ===
                                                                null && (
                                                                <MdLocalShipping className="text-red" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "paid" && (
                                                                <FaEuroSign className="text-green" />
                                                            )}{" "}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "partially_paid" && (
                                                                <FaEuroSign className="text-paid" />
                                                            )}
                                                            {statsTdb["orders"][
                                                                orderId
                                                            ]
                                                                .financial_status ===
                                                                "pending" && (
                                                                <FaEuroSign className="text-red" />
                                                            )}
                                                        </a>
                                                    </span>
                                                ))}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-velos-not-livrés">
                                    <td className="bg-table sticky-left">
                                        Total vélos NON livrés
                                    </td>{" "}
                                    <td className="bg-table"></td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td className="bg-table"></td>
                                        ))}
                                </tr>
                                <tr id="q_velos_not_livres">
                                    <td className=" sticky-left">Q.</td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "q_velos_not_livres"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_velos_not_livres"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="details_velos_not_livres">
                                    <td className=" sticky-left">C.</td>{" "}
                                    <td>
                                        {statsTdb["stats"][
                                            "details_velos_not_livres"
                                        ].length > 0 &&
                                            statsTdb["stats"][
                                                "details_velos_not_livres"
                                            ].map((item) => (
                                                <a
                                                    href={
                                                        "https://admin.shopify.com/store/velomad/orders/" +
                                                        item.split("&")[0]
                                                    }
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    className="commandes-velos"
                                                >
                                                    {item.split("&")[1]} - Q :{" "}
                                                    {item.split("&Q:")[1]}{" "}
                                                    <MdLocalShipping className="text-red" />
                                                </a>
                                            ))}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {statsTdb["stats"]["date"][key][
                                                    "details_velos_not_livres"
                                                ].length > 0 &&
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "details_velos_not_livres"
                                                    ].map((item) => (
                                                        <a
                                                            href={
                                                                "https://admin.shopify.com/store/velomad/orders/" +
                                                                item.split(
                                                                    "&"
                                                                )[0]
                                                            }
                                                            rel="noreferrer"
                                                            target="_blank"
                                                            className="commandes-velos"
                                                        >
                                                            {item.split("&")[1]}{" "}
                                                            - Q :{" "}
                                                            {
                                                                item.split(
                                                                    "&Q:"
                                                                )[1]
                                                            }{" "}
                                                            <MdLocalShipping className="text-red" />
                                                        </a>
                                                    ))}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="rac_ttc_velos_not_livres">
                                    <td className=" sticky-left">
                                        Rac totalité/commandeTTC
                                    </td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "rac_ttc_velos_not_livres"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "rac_ttc_velos_not_livres"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total_ht_velos_not_livres">
                                    <td className=" sticky-left">Total HT</td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_ht_velos_not_livres"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ][
                                                        "total_ht_velos_not_livres"
                                                    ]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="tva_velos_not_livres">
                                    <td className=" sticky-left">Tva</td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "tva_velos_not_livres"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["tva_velos_not_livres"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total-velos-livrés">
                                    <td className="bg-table sticky-left">
                                        Total vente vélos
                                    </td>{" "}
                                    <td className="bg-table"></td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td className="bg-table"></td>
                                        ))}
                                </tr>
                                <tr id="q_velos_livres">
                                    <td className=" sticky-left">
                                        Q.Vélos Livrés
                                    </td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"]["q_velos_livres"]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["q_velos_livres"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="details_velos_livres">
                                    <td className=" sticky-left">
                                        C.Vélos Livrés
                                    </td>{" "}
                                    <td>
                                        {statsTdb["stats"][
                                            "details_velos_livres"
                                        ].map((item) => (
                                            <a
                                                href={
                                                    "https://admin.shopify.com/store/velomad/orders/" +
                                                    item.split("&")[0]
                                                }
                                                rel="noreferrer"
                                                target="_blank"
                                                className="commandes-velos"
                                            >
                                                {item.split("&")[1]} - Q :{" "}
                                                {item.split("&Q:")[1]}{" "}
                                                <MdLocalShipping className="text-green" />
                                            </a>
                                        ))}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {statsTdb["stats"]["date"][key][
                                                    "details_velos_livres"
                                                ].map((item) => (
                                                    <a
                                                        href={
                                                            "https://admin.shopify.com/store/velomad/orders/" +
                                                            item.split("&")[0]
                                                        }
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="commandes-velos"
                                                    >
                                                        {item.split("&")[1]} - Q
                                                        : {item.split("&Q:")[1]}{" "}
                                                        <MdLocalShipping className="text-green" />
                                                    </a>
                                                ))}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="rac_ttc_velos_livres">
                                    <td className=" sticky-left">
                                        Rac totalité/commandeTTC
                                    </td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "rac_ttc_velos_livres"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["rac_ttc_velos_livres"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="total_ht_velos_livres">
                                    <td className=" sticky-left">Total HT</td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "total_ht_velos_livres"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["total_ht_velos_livres"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr id="tva_velos_livres">
                                    <td className=" sticky-left">Tva</td>{" "}
                                    <td>
                                        {Number(
                                            statsTdb["stats"][
                                                "tva_velos_livres"
                                            ]
                                        ).toFixed(2)}
                                    </td>
                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <td>
                                                {Number(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["tva_velos_livres"]
                                                ).toFixed(2)}
                                            </td>
                                        ))}
                                </tr>
                                <tr>
                                    <th className="bg-table sticky-top sticky-left z-index-4">
                                        Date
                                    </th>
                                    <th className="bg-table sticky-top"></th>

                                    {statsTdb !== null &&
                                        Object.keys(
                                            statsTdb["stats"]["date"]
                                        ).map((key, index) => (
                                            <th
                                                className="bg-table sticky-top"
                                                key={index}
                                            >
                                                {key}
                                            </th>
                                        ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            ) : (
                <>
                    <p className="text-center">
                        Merci de sélectionner vote Période d'extraction de
                        données (max : 30j conseillé)
                    </p>
                    <p className="text-center">
                        Le temps d'extraction est de 15 secondes environ pour 30
                        jours
                    </p>
                </>
            )}

            {dateStart !== null && dateEnd !== null && statsTdb !== null ? (
                <div className="table-responsive">
                    <table id="TdbDetailsTable2">
                        <thead>
                            <tr>
                                <th className="bg-table sticky-top sticky-left z-index-4">
                                    Date
                                </th>
                                <th className="bg-table sticky-top">Total</th>

                                {statsTdb !== null &&
                                    Object.keys(statsTdb["stats"]["date"]).map(
                                        (key, index) => (
                                            <th
                                                className="bg-table sticky-top"
                                                key={index}
                                            >
                                                {key}
                                            </th>
                                        )
                                    )}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="velos-vendus">
                                <td className="sticky-left">Vélos vendus</td>{" "}
                                <td></td>
                                {statsTdb !== null &&
                                    Object.keys(statsTdb["stats"]["date"]).map(
                                        (key, index) => (
                                            <td>
                                                {statsTdb["stats"]["date"][
                                                    key
                                                ] !== null &&
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ] !== undefined &&
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["velo"] !== undefined &&
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["velo"] !== null &&
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["velo"].length > 0 &&
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["velo"].map(
                                                        (velo, index) => (
                                                            <span className="my-1 text-center">
                                                                <IoIosBicycle />{" "}
                                                                {velo.quantity}{" "}
                                                                x {velo.name}{" "}
                                                                {statsTdb[
                                                                    "orders"
                                                                ][velo.order.id]
                                                                    .fulfillment_status ===
                                                                    "fulfilled" && (
                                                                    <MdLocalShipping className="text-green" />
                                                                )}{" "}
                                                                {statsTdb[
                                                                    "orders"
                                                                ][velo.order.id]
                                                                    .fulfillment_status ===
                                                                    "partial" && (
                                                                    <MdLocalShipping className="text-paid" />
                                                                )}
                                                                {statsTdb[
                                                                    "orders"
                                                                ][velo.order.id]
                                                                    .fulfillment_status ===
                                                                    null && (
                                                                    <MdLocalShipping className="text-red" />
                                                                )}
                                                                {statsTdb[
                                                                    "orders"
                                                                ][velo.order.id]
                                                                    .financial_status ===
                                                                    "paid" && (
                                                                    <FaEuroSign className="text-green" />
                                                                )}{" "}
                                                                {statsTdb[
                                                                    "orders"
                                                                ][velo.order.id]
                                                                    .financial_status ===
                                                                    "partially_paid" && (
                                                                    <FaEuroSign className="text-paid" />
                                                                )}
                                                                {statsTdb[
                                                                    "orders"
                                                                ][velo.order.id]
                                                                    .financial_status ===
                                                                    "pending" && (
                                                                    <FaEuroSign className="text-red" />
                                                                )}
                                                                <a
                                                                    href={
                                                                        "https://admin.shopify.com/store/velomad/orders/" +
                                                                        velo
                                                                            .order
                                                                            .id
                                                                    }
                                                                    rel="noreferrer"
                                                                    target="_blank"
                                                                    className="commandes-velos"
                                                                >
                                                                    {
                                                                        velo
                                                                            .order
                                                                            .name
                                                                    }
                                                                    #{" "}
                                                                    {
                                                                        velo
                                                                            .order
                                                                            .tags
                                                                    }{" "}
                                                                    {statsTdb[
                                                                        "orders"
                                                                    ][
                                                                        velo
                                                                            .order
                                                                            .id
                                                                    ]
                                                                        .location_id ===
                                                                        88642617625 &&
                                                                        "#paris"}
                                                                    {statsTdb[
                                                                        "orders"
                                                                    ][
                                                                        velo
                                                                            .order
                                                                            .id
                                                                    ]
                                                                        .location_id ===
                                                                        88647401753 &&
                                                                        "#rouen"}
                                                                    {statsTdb[
                                                                        "orders"
                                                                    ][
                                                                        velo
                                                                            .order
                                                                            .id
                                                                    ]
                                                                        .location_id ===
                                                                        90150437145 &&
                                                                        "#b2b"}
                                                                    {statsTdb[
                                                                        "orders"
                                                                    ][
                                                                        velo
                                                                            .order
                                                                            .id
                                                                    ]
                                                                        .location_id ===
                                                                        null &&
                                                                        "#site"}
                                                                </a>
                                                                <hr />
                                                            </span>
                                                        )
                                                    )}
                                            </td>
                                        )
                                    )}
                            </tr>{" "}
                            <tr id="commande-total">
                                <td className="bg-table sticky-left">
                                    Toutes Commandes
                                </td>{" "}
                                <td className="bg-table"></td>
                                {statsTdb !== null &&
                                    Object.keys(statsTdb["stats"]["date"]).map(
                                        (key, index) => (
                                            <td className="bg-table">
                                                {Object.keys(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["order"]
                                                ).map((orderId, index) => (
                                                    <a
                                                        href={
                                                            "https://admin.shopify.com/store/velomad/orders/" +
                                                            orderId
                                                        }
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="commandes-velos"
                                                    >
                                                        {
                                                            statsTdb["stats"][
                                                                "date"
                                                            ][key]["order"][
                                                                orderId
                                                            ]
                                                        }{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "fulfilled" && (
                                                            <MdLocalShipping className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "partial" && (
                                                            <MdLocalShipping className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            null && (
                                                            <MdLocalShipping className="text-red" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "paid" && (
                                                            <FaEuroSign className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "partially_paid" && (
                                                            <FaEuroSign className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "pending" && (
                                                            <FaEuroSign className="text-red" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88642617625 &&
                                                            "#paris"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88647401753 &&
                                                            "#rouen"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            90150437145 &&
                                                            "#b2b"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            null && "#site"}
                                                    </a>
                                                ))}
                                            </td>
                                        )
                                    )}
                            </tr>
                            <tr id="commande-precommande">
                                <td className="sticky-left">precommande</td>{" "}
                                <td></td>
                                {statsTdb !== null &&
                                    Object.keys(statsTdb["stats"]["date"]).map(
                                        (key, index) => (
                                            <td>
                                                {Object.keys(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["order_precommande_velo"]
                                                ).map((orderId, index) => (
                                                    <a
                                                        href={
                                                            "https://admin.shopify.com/store/velomad/orders/" +
                                                            orderId
                                                        }
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="commandes-velos"
                                                    >
                                                        {
                                                            statsTdb["stats"][
                                                                "date"
                                                            ][key][
                                                                "order_precommande_velo"
                                                            ][orderId]
                                                        }
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "fulfilled" && (
                                                            <MdLocalShipping className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "partial" && (
                                                            <MdLocalShipping className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            null && (
                                                            <MdLocalShipping className="text-red" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "paid" && (
                                                            <FaEuroSign className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "partially_paid" && (
                                                            <FaEuroSign className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "pending" && (
                                                            <FaEuroSign className="text-red" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88642617625 &&
                                                            "#paris"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88647401753 &&
                                                            "#rouen"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            90150437145 &&
                                                            "#b2b"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            null && "#site"}
                                                    </a>
                                                ))}
                                            </td>
                                        )
                                    )}
                            </tr>
                            <tr id="commande-fabrication">
                                <td className="sticky-left">fabrication</td>{" "}
                                <td></td>
                                {statsTdb !== null &&
                                    Object.keys(statsTdb["stats"]["date"]).map(
                                        (key, index) => (
                                            <td>
                                                {Object.keys(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["order_fabrication_velo"]
                                                ).map((orderId, index) => (
                                                    <a
                                                        href={
                                                            "https://admin.shopify.com/store/velomad/orders/" +
                                                            orderId
                                                        }
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="commandes-velos"
                                                    >
                                                        {
                                                            statsTdb["stats"][
                                                                "date"
                                                            ][key][
                                                                "order_fabrication_velo"
                                                            ][orderId]
                                                        }
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "fulfilled" && (
                                                            <MdLocalShipping className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "partial" && (
                                                            <MdLocalShipping className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            null && (
                                                            <MdLocalShipping className="text-red" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "paid" && (
                                                            <FaEuroSign className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "partially_paid" && (
                                                            <FaEuroSign className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "pending" && (
                                                            <FaEuroSign className="text-red" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88642617625 &&
                                                            "#paris"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88647401753 &&
                                                            "#rouen"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            90150437145 &&
                                                            "#b2b"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            null && "#site"}
                                                    </a>
                                                ))}
                                            </td>
                                        )
                                    )}
                            </tr>
                            <tr id="commande-reconditionne">
                                <td className="sticky-left">reconditionne</td>{" "}
                                <td></td>
                                {statsTdb !== null &&
                                    Object.keys(statsTdb["stats"]["date"]).map(
                                        (key, index) => (
                                            <td>
                                                {Object.keys(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["order_reconditonne_velo"]
                                                ).map((orderId, index) => (
                                                    <a
                                                        href={
                                                            "https://admin.shopify.com/store/velomad/orders/" +
                                                            orderId
                                                        }
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="commandes-velos"
                                                    >
                                                        {
                                                            statsTdb["stats"][
                                                                "date"
                                                            ][key][
                                                                "order_reconditonne_velo"
                                                            ][orderId]
                                                        }
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "fulfilled" && (
                                                            <MdLocalShipping className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "partial" && (
                                                            <MdLocalShipping className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            null && (
                                                            <MdLocalShipping className="text-red" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "paid" && (
                                                            <FaEuroSign className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "partially_paid" && (
                                                            <FaEuroSign className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "pending" && (
                                                            <FaEuroSign className="text-red" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88642617625 &&
                                                            "#paris"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88647401753 &&
                                                            "#rouen"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            90150437145 &&
                                                            "#b2b"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            null && "#site"}
                                                    </a>
                                                ))}
                                            </td>
                                        )
                                    )}
                            </tr>
                            <tr id="commande-stockb2b">
                                <td className="sticky-left">StockB2B</td>{" "}
                                <td></td>
                                {statsTdb !== null &&
                                    Object.keys(statsTdb["stats"]["date"]).map(
                                        (key, index) => (
                                            <td>
                                                {Object.keys(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["order_stockb2b_velo"]
                                                ).map((orderId, index) => (
                                                    <a
                                                        href={
                                                            "https://admin.shopify.com/store/velomad/orders/" +
                                                            orderId
                                                        }
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="commandes-velos"
                                                    >
                                                        {
                                                            statsTdb["stats"][
                                                                "date"
                                                            ][key][
                                                                "order_stockb2b_velo"
                                                            ][orderId]
                                                        }
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "fulfilled" && (
                                                            <MdLocalShipping className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "partial" && (
                                                            <MdLocalShipping className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            null && (
                                                            <MdLocalShipping className="text-red" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "paid" && (
                                                            <FaEuroSign className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "partially_paid" && (
                                                            <FaEuroSign className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "pending" && (
                                                            <FaEuroSign className="text-red" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88642617625 &&
                                                            "#paris"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88647401753 &&
                                                            "#rouen"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            90150437145 &&
                                                            "#b2b"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            null && "#site"}
                                                    </a>
                                                ))}
                                            </td>
                                        )
                                    )}
                            </tr>
                            <tr id="commande-stock">
                                <td className="sticky-left">Autres & stock</td>{" "}
                                <td></td>
                                {statsTdb !== null &&
                                    Object.keys(statsTdb["stats"]["date"]).map(
                                        (key, index) => (
                                            <td>
                                                {Object.keys(
                                                    statsTdb["stats"]["date"][
                                                        key
                                                    ]["order_stock_velo"]
                                                ).map((orderId, index) => (
                                                    <a
                                                        href={
                                                            "https://admin.shopify.com/store/velomad/orders/" +
                                                            orderId
                                                        }
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className="commandes-velos"
                                                    >
                                                        {
                                                            statsTdb["stats"][
                                                                "date"
                                                            ][key][
                                                                "order_stock_velo"
                                                            ][orderId]
                                                        }
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "fulfilled" && (
                                                            <MdLocalShipping className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            "partial" && (
                                                            <MdLocalShipping className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].fulfillment_status ===
                                                            null && (
                                                            <MdLocalShipping className="text-red" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "paid" && (
                                                            <FaEuroSign className="text-green" />
                                                        )}{" "}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "partially_paid" && (
                                                            <FaEuroSign className="text-paid" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].financial_status ===
                                                            "pending" && (
                                                            <FaEuroSign className="text-red" />
                                                        )}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88642617625 &&
                                                            "#paris"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            88647401753 &&
                                                            "#rouen"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            90150437145 &&
                                                            "#b2b"}
                                                        {statsTdb["orders"][
                                                            orderId
                                                        ].location_id ===
                                                            null && "#site"}
                                                    </a>
                                                ))}
                                            </td>
                                        )
                                    )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <></>
            )}
        </main>
    );
}
